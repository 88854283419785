import './App.css';
import AboutUs from './components/AboutUs/AboutUs';
import Benefits from './components/Benefits/Benefits';
import CallButton from './components/CallButton/CallButton';
import Cover from './components/Cover/Cover';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import MainForm from './components/MainForm/MainForm';
import Map from './components/Map/Map';
import ModalForm from './components/ModalForm/ModalForm';
import Prices from './components/Prices/Prices';
import Repair from './components/Repair/Repair';
import Steps from './components/Steps/Steps';
import Team from './components/Team/Team';

function App() {
  return (
    <div className="App">
    <Header />
    <Cover />
    <Benefits />
    <AboutUs />
    <Repair />
    <Prices />
    <Steps />
    <MainForm />
    <Team />
    <Map />
    <Footer />
    <CallButton />
    <ModalForm />
    </div>
  );
}

export default App;
