import './Team.css'
import personImageOne from '../../assets/images/person-image-one.webp'
import personImageTwo from '../../assets/images/person-image-two.webp'
import personImageThree from '../../assets/images/person-image-three.webp'
const Team = () => {
  return (
    <section className='team__section'>
        <h2 className="team__section-title mont-b">Наша команда</h2>
        <h3 className="team__section-description int-m">Наша команда - це группа спеціалістів із досвідом від 8 років, які впораються з поломкою будь-якої важкості</h3>
        <div className="team">
            <div className="team__elipse"></div>
            <div className="team__elipse-two"></div>
            <div className="person">
                <img src={personImageTwo} alt="член нашої команди" className="person__image" />
                <div className="person__popup">
                    <div className="person__popup-title mont-b">Максим</div>
                    <p className="perspn__popup-description int-m">Старший майстер з ремонту цифрової техніки, кваліфікований спеціаліст в електротехнічній галузі.</p>
                </div>
            </div>
            <div className="person">
                <img src={personImageOne} alt="член нашої команди" className="person__image" />
                <div className="person__popup">
                    <div className="person__popup-title mont-b">Дмитро</div>
                    <p className="perspn__popup-description int-m">Провідний технік, спеціаліст з обслуговування клієнтів та технічного оснащення майстерні.</p>
                </div>
            </div>
            <div className="person">
                <img src={personImageThree} alt="член нашої команди" className="person__image" />
                <div className="person__popup">
                    <div className="person__popup-title mont-b">Олексій</div>
                    <p className="perspn__popup-description int-m">Досвідчений майстер із ремонту комп’ютерної техніки, фахівець  у сфері Digital послуг.</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Team