import './Footer.css'


const Footer = () => {
  return (
    <footer className='footer'>
      <div className="footer__wrapper">
        <h2 className="footer__logo arial-b">Tech<span className='brand-color'>Fix</span></h2>
        <a className='footer__phone mont-b' href="tel:+380671624998">+38(067)162-49-98</a>
      </div>
    </footer>
  )
}

export default Footer