import './Map.css'

function Map() {
  return (
    <section id="contacts" className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2541.3676328491665!2d30.458178699999994!3d50.4342526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cf0021b3be1f%3A0x6272a20dfc8c170a!2z0JzQsNGB0YLQtdGA0YHQutCw0Y8g0L_QviDRgNC10LzQvtC90YLRgyDQutC-0LzQvy7RgtC10YXQvdC40LrQuCDQuCDRgtC10LvQtdCy0LjQt9C-0YDQvtCy!5e0!3m2!1sru!2sua!4v1718276285196!5m2!1sru!2sua"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title='карта як нас знайти'
      ></iframe>
    </section>
  );
}

export default Map;
