import "./ModalForm.css";
import { useState } from "react";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { setClearIsFormSended, setIsClose } from "../../redux/ModalSlice/ModalSlice";
import closeIcon from '../../assets/icons/plusIcon.png'
import { useTelegramMessage } from "../../utils/request";
import { useEffect } from "react";
import sendedIcon from '../../assets/icons/form-sended-icon.png'

const ModalForm = () => {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.modal.isActive);
  const response = useSelector((state) => state.modal.isFormSended);
  let timeoutId;
  const [isResponseActive, setIsResponseActive] = useState(false);
  const [userName, setUserName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(false);
  const { sendTelegramMessage } = useTelegramMessage();

  useEffect(() => {
    setIsResponseActive(response);

    if (isResponseActive) {
      timeoutId = setTimeout(() => {
        dispatch(setClearIsFormSended());
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [response, isResponseActive, dispatch]);


  const handleCloseForm = () => {
    dispatch(setIsClose())
  }

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setNameError(false);
  };

  const handlePhoneNumberChange = (e) => {
    setUserPhone(e.target.value);
    setPhoneError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("sbmt");
    let nameErrorFlag = false;
    let phoneErrorFlag = false;

    if (userName.length < 1) {
      setNameError(true);
      nameErrorFlag = true;
    } else {
      setNameError(false);
    }

    if (userPhone && !userPhone.includes("_")) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      phoneErrorFlag = true;
    }


      if (!nameErrorFlag && !phoneErrorFlag) {
        const message = `\u{1F408}ЗАЯВКА!!!\u{1F408}\nИмя: ${userName}\nНомер телефона: ${userPhone}\n`;
  
        await sendTelegramMessage(message);
  
        setUserName("");
        setUserPhone("");
        
      }
      
    

  };
  return (
    <div className={`modal__form ${isActive ? "active" : ""}`}>
      <div onClick={handleCloseForm} className="modal__form-overlay">
        <div onClick={(e) => e.stopPropagation()} className={`modal__form-body ${isResponseActive ? "hidden" : ""}`}>
          <img onClick={handleCloseForm}  src={closeIcon} alt="закрити форму" className="close__icon" />
          <h2 className="modal__form-title mont-b">Заявка на консультацію</h2>
          <h3 className="modal__form-description mont-b">
            Зателефонуємо вам протягом <span className="brand-color">5 хвилин!</span>
          </h3>
          <form
            onSubmit={handleSubmit}
            className="modal__form-form"
          >
            <div className="form__inputs-wrapper">
            <input
              placeholder="Ім’я"
              value={userName}
              onChange={handleNameChange}
              className={`modal__form-input ${nameError ? "error" : ""} mont-b`}
              type="text"
            />
            <ReactInputMask
              mask="+380 (99) 999-99-99"
              value={userPhone}
              onChange={handlePhoneNumberChange}
              placeholder="Номер телефону"
              onFocus={(e) => e.target.setSelectionRange(6, 6)}
              onBlur={(e) => {
                if (e.target.value === "+380 ()") {
                  setUserPhone("");
                }
              }}
              className={`modal__form-input ${PhoneError ? "error" : ""} mont-b`}
              type="phone"
            />
            </div>
            <button
              onSubmit={handleSubmit}
              className="modal__form-button mont-b"
              type="submit"
            >
              Передзвоніть мені
            </button>
          </form>{" "}
        </div>
        <div className={`modal__form-body-response ${isResponseActive ? "active" : ""}`}>
        <img onClick={handleCloseForm}  src={closeIcon} alt="закрити форму" className="close__icon" />
            <img src={sendedIcon} alt="форма відправлено успішко картинка" className="sended-icon" />
            <h2 className="sended-title mont-b">Форма успішно відправлена</h2>
            <p className="sended-description mont-b">Зателефонуємо вам протягом <span className="brand-color">5 хвилин!</span></p>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
